/* footer style */
.footer {
    padding: 20px;
    background-color: #7d512e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-text {
    color: #fff;
    margin: 0;
}

.footer-link {
    color: #fff;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}