.videos {
    width: 100%;
    min-height: 100vh;
    background: url('./S3.png') no-repeat center center / cover;
    position: relative;
    padding: 80px 20px 20px;
}

.videos-monkey--box {
    display: none;
    max-width: 450px;
    position: absolute;
    left: 38%;
    top: 40%;
    transform: translate(-50%, 0);
}

.videos-monkey--image {
    width: 100%;
    animation: vibrate 2s linear infinite;
}

.image-fluid {
    border-radius: 50%;
}

.videos-container {
    max-width: 75%;
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px #000;
    aspect-ratio: 16/9;
}

.videos-title {
    color: #fff;
    text-align: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 700;
    font-size: 35px;
    text-decoration: underline;
    text-shadow: 0 0 20px #448FEE;
}

.keys-container,
.videos-container {
    max-width: 75%;
    margin: 50px auto;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.videos-container iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
}

.content-title {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    animation: boxPulse 2s infinite alternate;
    transition: all .4s;
    filter: drop-shadow(0 5px 10px rgba(68, 143, 239, 0.7));
}

.content-title:nth-child(2) {
    animation-delay: 0.4s;
}

.content-title:nth-child(3) {
    animation-delay: 0.8s;
}

.content-title:nth-child(4) {
    animation-delay: 1.2s;
}

.content-title:nth-child(5) {
    animation-delay: 1.6s;
}

.content-title:nth-child(6) {
    animation-delay: 2s;
}

.content-title:hover {
    filter: drop-shadow(0 5px 10px rgba(255, 255, 255, 1));
}

.videos-keys-text {
    font-size: 20px !important;
    text-transform: capitalize;
}

/* mediaquery */
@media (min-width: 1200px) {
    .videos-monkey--box {
        display: block;
    }
}

@keyframes boxPulse {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}