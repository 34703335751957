.header {
    width: 100%;
    min-height: 100vh;
    /* min-height: 400px; */
    background: url('./header-background.png') no-repeat center center / cover;
    position: relative;
    display: flex;
    align-items: end;
    justify-content: center;
}

.header-teacher {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    /* position: absolute;
    left: 25%;
    bottom: 20px; */
}

.header-teacher--image {
    width: 100%;
    filter: drop-shadow(0 20px 10px rgba(0, 0, 0, 0.8));
    /* animation: skew-x-shakeng 2s linear infinite; */
}

/* .header-message {
    width: 450px;
    height: 450px;
    position: absolute;
    left: 40%;
    top: 10%;
    animation: shake 5s linear infinite;
}

.header-message--image {
    width: 100%;
} */